var g;
// This works in non-strict mode
g = function () {
    return this;
}();
try {
    // This works if eval is allowed (see CSP)
    g = g || new Function('return this')();
} catch (e) {
    // This works if the window reference is available
    if (typeof window === 'object')
        g = window;
}
// g can still be undefined, but nothing to do about it...
// We return undefined, instead of nothing here, so it's
// easier to handle this case. if(!global) { ...}
module.exports = g;